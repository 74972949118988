<template>
  <b-overlay :show="loading">
    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
        <ValidationProvider name="Schedule Setup" vid="schedule_setup" rules="required">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="schedule_setup"
              slot-scope="{ valid, errors }"
          >
            <template v-slot:label>
              {{ $t('elearning_config.schedule_setup') }} ({{ $t('globalTrans.en') }}) <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="schedule_setup"
              v-model="formData.title"
              :state="errors[0] ? false : (valid ? true : null)"
            ></b-form-input>
            <div class="invalid-feedback">
            {{ errors[0] }}
            </div>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider name="Schedule Setup (Bn)" vid="schedule_setup_bn" rules="required">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="schedule_setup_bn"
              slot-scope="{ valid, errors }"
          >
          <template v-slot:label>
              {{$t('elearning_config.schedule_setup')}} ({{ $t('globalTrans.bangla') }}) <span class="text-danger">*</span>
              </template>
              <b-form-input
              id="schedule_setup_bn"
              v-model="formData.title_bn"
              :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-input>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider name="Order" vid="order" rules="required">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="order"
              slot-scope="{ valid, errors }"
          >
          <template v-slot:label>
              {{ $t('globalTrans.order') }}  <span class="text-danger">*</span>
              </template>
              <b-form-input
              id="schedule_setup_bn"
              v-model="formData.order"
              :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-input>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
        </ValidationProvider>
        <div class="row">
        <div class="col-sm-3"></div>
        <div class="col text-right">
            <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
        </div>
        </div>
    </b-form>
    </ValidationObserver>
  </b-overlay>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { scheduleSetupStore, scheduleSetupUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getEditingData()
      this.formData = tmp
    }
    this.getOrganizationlist()
  },
  mounted () {
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      scheduleSetupStore: scheduleSetupStore,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        title: '',
        title_bn: '',
        order: ''
      }
    }
  },
  computed: {
  },
  methods: {
    getEditingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${scheduleSetupUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, scheduleSetupStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    getOrganizationlist () {
      const orgList = this.$store.state.commonObj.organizationProfileList
      this.organizationList = orgList.map(obj => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    }
  }
}
</script>
