<template>
  <div class="inner-section">
     <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('elearning_config.schedule_setup') }}</h4> </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('elearning_config.schedule_setup')"
              label-for="schedule_setup"
            >
              <b-form-input
                id="schedule_setup"
                v-model="search.schedule_setup"
                placeholder=""
                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
              <b-button size="sm" variant="primary" @click="searchData">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
              </b-button>
          </b-col>
        </b-row>
      </template>
    </card>
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('elearning_config.schedule_setup') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-4><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
      </template>
      <template v-slot:body>
        <select-column-check :labelData="labelData" :search="search" :columns="columns" />
        <b-overlay :show="loadingState">
          <b-row>
            <b-col md="12" class="table-responsive">
              <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                <template v-slot:cell(index)="data">
                  {{ $n(data.index + pagination.slOffset) }}
                </template>
                <template v-slot:cell(schedule_setup)="data">
                  <span class="capitalize">{{ data.item.schedule_setup }}</span>
                </template>
                <template v-slot:cell(order)="data">
                  {{ $n(data.item.order) }}
                </template>
                <template v-slot:cell(status)="data">
                    <span class="badge badge-success" v-if="data.item.status === 1">{{$t('globalTrans.active')}}</span>
                    <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                </template>
                <template v-slot:cell(action)="data">
                    <a href="javascript:" class="btn_table_action table_action_edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></a>
                    <!-- <b-button v-b-modal.modal-4 variant="btn-edit" size="sm" @click="edit(data.item)" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button> -->
                </template>
              </b-table>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                v-model="pagination.currentPage"
                :perPage="search.limit"
                :total-rows="pagination.totalRows"
                @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
    </body-card>
    <b-modal id="modal-4" size="lg" hide-footer :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>
  </div>
</template>
<script>

import FormV from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { scheduleSetupList, scheduleSetupToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import { mapGetters } from 'vuex'

export default {
  name: 'UiDataTable',
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      search: {
        schedule_setup: '',
        limit: 20
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
        { labels: 'elearning_config.schedule_setup', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '30%' } },
        { labels: 'globalTrans.order', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '30%' } },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 4, thStyle: { width: '15%' } }
      ]
    }
  },
  computed: {
     ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    organizationtList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('elearning_config.schedule_setup') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.schedule_setup') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'title_bn' },
          { key: 'order' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'title' },
          { key: 'order' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(trainingElearningServiceBaseUrl, scheduleSetupToggleStatus, item, 'training_e_learning', 'scheduleSetupList')
      // this.changeStatus(trainingElearningServiceBaseUrl, scheduleSetupToggleStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(trainingElearningServiceBaseUrl, scheduleSetupList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            // this.$store.dispatch('setList', this.dataList(response.data.data))
            this.paginationData(response.data, this.search.limit)
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
    // dataList (data) {
    //   const orgList = this.$store.state.orgList
    //   let tmpData = {}
    //   const listData = data.map(item => {
    //     tmpData = orgList.find(orgItem => orgItem.value === item.org_id)
    //     const orgData = { org_name: tmpData.text_en, org_name_bn: tmpData.text_bn }
    //     return Object.assign({}, item, orgData)
    //   })
    //   return listData
    // }
  }
}
</script>
